<template>
  <div>
    <div class="add main-block">
      <div class="add__box main-block__head">
        <h1 class="add__box-title main-block__title">Информация</h1>
        <button type="submit" class="main-block__btn default-btn">Сохранить</button>
      </div>
      <ul class="add__items">
        <li class="add__item flex">
          <p class="add__key">Организация</p>
          <input type="text" readonly="readonly" class="add__value" v-on:keydown.enter.prevent="$event.target.nextElementSibling.focus()" placeholder="Организация" @focusout="onChange('organization', organization)" v-model="organization" required />
        </li>
        <li class="add__item flex">
          <p class="add__key">Склад *</p>
          <input type="text" readonly="readonly" class="add__value" v-on:keydown.enter.prevent="$event.target.nextElementSibling.focus()" placeholder="Склад" @focusout="onChange('stock', stock)" v-model="stock"  required />
        </li>
        <li class="add__item flex">
          <p class="add__key">Транспорт</p>
          <b-form-select
            v-model="transport"
            class="add__value"
            :options="listTransport"
            text-field="name"
            value-field="id"
            @change="onChange('transport', transport)"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Водитель *</p>
          <b-form-select
            v-model="driver"
            class="add__value"
            :options="listDrivers"
            text-field="name"
            value-field="id"
            @change="onChange('driver', driver)"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Направление *</p>
          <b-form-select
            v-model="direction_id"
            class="add__value"
            :options="listRegions"
            text-field="title"
            value-field="id"
            @change="onChange('direction_id', direction_id)"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Дата выезда *</p>
          <input class="add__value"
                 :placeholder="'Дата выезда'"
                 readonly="readonly"
                 v-model="date"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Время выезда *</p>
          <input v-mask="'##:##'" v-on:keydown.enter.prevent="$event.target.nextElementSibling.focus()" @focusout="onChange('time', time)" v-model="time" placeholder="##:##" class="add__value" required />
        </li>
      </ul>
    </div>
    <div v-if="context" class="main-close" @click="context = null" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      context: null,
      organization: 'ТОО Aqniet Group',
      stock: 'Основной склад ТОО Aqniet Group',
      transport: null,
      driver: null,
      date: null,
      time: null,
      direction_id: null,
    };
  },
  computed: {
    ...mapState(["regionList", "transportList", "driversList"]),
    listRegions() {
      if (this.regionList && this.regionList.data && this.regionList.data.length > 0) {
        return [{ id: null, title: "Направление" }, ...this.regionList.data];
      }
      return [{ id: null, title: "Направление" }];
    },
    listTransport() {
      let transports = [
        {
          id: null,
          name: 'Выберите'
        }
      ];
      if (this.transportList) {
        this.transportList.forEach((e) => {
          transports.push({ id: e.id, name: e.model?.name + "|" + e.number + "|" + e.body });
        });
        return transports;
      }
      return transports;
    },
    listDrivers() {
      if (this.driversList) {
        return [{ id: null, name: "Выберите" }, ...this.driversList.data];
      }
      return [{ id: null, name: "Выберите", disabled: true }];
    },
  },
  async created() {
    await this.$store.dispatch("getRegionList");
    this.$store.dispatch("getTransportList");
    this.$store.dispatch("getDriversList");
    this.getNextTuesdayOrFriday();
  },
  methods: {
    onContext(ctx) {
      this.context = ctx
    },
    async onChange(key, param) {
      this.$emit("onInputHandler", {
        key,
        param,
      });
    },
    getNextTuesdayOrFriday() {
      const today = new Date();
      const currentDay = today.getDay();
      const daysUntilNextTuesday = (2 - currentDay + 7) % 7;
      const daysUntilNextFriday = (5 - currentDay + 7) % 7;

      const nextTuesday = new Date(today);
      nextTuesday.setDate(today.getDate() + daysUntilNextTuesday);

      const nextFriday = new Date(today);
      nextFriday.setDate(today.getDate() + daysUntilNextFriday);

      if (nextTuesday < nextFriday) {
        this.date = nextTuesday.toLocaleDateString('ru-RU', { day:"numeric", month:"numeric", year: "numeric"});
      } else {
        this.date = nextFriday.toLocaleDateString('ru-RU', { day:"numeric", month:"numeric", year: "numeric"});
      }

      this.onChange('date', this.date)
    }
  },
};
</script>

<style scoped>
.add {
  min-height: auto;
  padding-bottom: 30px;
}

.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
.city-add__map {
  margin-top: 24px;
  height: 300px;
  width: 100%;
}
.city-add__search-list {
  width: 50%;
  max-height: 250px;
  padding: 10px;
  border-radius: 0px 0px 20px 20px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgba(16, 51, 115, 0.08);
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 2;
}
.city-add__search-item {
  padding: 10px 5px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
}
.city-add__search-item:not(:last-child) {
  border-bottom: 1px solid #b4b4b4;
}
.city-add__search-item:hover {
  background-color: rgba(203, 206, 225, 0.71);
}
</style>
